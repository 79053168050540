import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Input, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import { sanitizeString, normalizeString } from '../../../shared/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // eslint-disable-next-line no-magic-numbers
            marginTop: theme.spacing(4),
            color: theme.palette.secondary.main,
            // eslint-disable-next-line no-magic-numbers
            marginBottom: theme.spacing(2),
        },
        inputFilter: {
            minWidth: 440,
            height: 48,
            // eslint-disable-next-line no-magic-numbers
            marginRight: theme.spacing(4),
        },
        textFieldFilter: {
            width: 224,
            // eslint-disable-next-line no-magic-numbers
            marginRight: theme.spacing(4),
        },
        cursorDefault: {
            cursor: 'default',
        },
    }),
);

interface GenericObject {
    [key: string]: any;
};

export interface SelectOption {
    value: string;
    label: string;
};

export interface SelectProps {
    selectLabel: string;
    selectAllLabel: string;
    selectOptions: SelectOption[];
    selectMatchField: string;
    matchFieldCustomProp?: string;
};

interface Props<T> {
    data: T[];
    setFiltered: (filtered: T[]) => void;
    setPaginated: (paginated: T[]) => void;
    setPage: (page: number) => void;

    searchPlaceholder: string;
    searchMatchFields: string[];

    selects?: SelectProps[];

    download?: boolean;
    downloadUrl?: string;
}

/**
 * Filter component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Filter<T extends GenericObject>(props: Props<T>): JSX.Element {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [selectValues, setSelectValues] = useState<string[]>([]);

    useEffect(() => {
        if (props.selects && props.selects.length) {
            const selectValuesArr: string[] = [...selectValues];
            props.selects.forEach((select, index) => {
                selectValuesArr[index] = select.selectAllLabel;
                setSelectValues(selectValuesArr);
            });
        }
    }, [props.selects]);

    useEffect(() => {
        filter();
    }, [props.data, selectValues, searchValue]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(sanitizeString(event.target.value));
    };

    const handleSelectChange = (value: string, index: number) => {
        const copySelectValues = [...selectValues];
        copySelectValues[index] = value;
        setSelectValues(copySelectValues);
    };

    const downloadURL = useMemo(() => {
        let filterValues = '';
        if (props.selects?.length) {
            props.selects.forEach(
                (select, index) => filterValues += `&${select.selectMatchField}=${selectValues[index]}`,
            );
        }
        return `${props.downloadUrl}?query=${searchValue}${filterValues}`;
    }, [selectValues, searchValue]);

    const getValue = (value: T, field: string) => {
        if (field.indexOf('.') !== -1) {
            const fields = field.split('.');
            const object = value[fields[0]];
            if (object) {
                return object[fields[1]];
            }
            return '-';
        }
        return value[field] || '-';
    };

    const searchMatcher = (object: T, inputValue: string) => {
        for (let index = 0; index < props.searchMatchFields.length; index++) {
            let objectValue = getValue(object, props.searchMatchFields[index]);
            if (typeof objectValue !== 'number') {
                objectValue = normalizeString(objectValue);
                inputValue = normalizeString(inputValue);
                if (objectValue.indexOf(inputValue) >= 0) {
                    return true;
                }
            } else {
                if (objectValue.toString() === inputValue) {
                    return true;
                }
            }
        }
        return false;
    };

    const getObjectValue = (object: T, select: SelectProps) => {
        if (select.matchFieldCustomProp) {
            return object[select.selectMatchField][select.matchFieldCustomProp];
        } else {
            return object[select.selectMatchField];
        }
    };

    const selectMatcher = (object: T) => {
        if (props.selects) {
            for (let i = 0; i < props.selects.length; i++) {
                const select = props.selects[i];
                const objectValue = getObjectValue(object, select);
                if (
                    selectValues[i] != objectValue &&
                    selectValues[i] !== select.selectAllLabel
                ) {
                    return false;
                }
            }
        }
        return true;
    };

    const filter = () => {
        let filtered = props.data;
        if (searchValue.length || selectValues.length) {
            filtered = props.data.filter((value: T) => {
                if (selectMatcher(value) && searchMatcher(value, searchValue)) {
                    return true;
                }
                return false;
            });
        }
        props.setPaginated([]);
        props.setPage(0);
        props.setFiltered(filtered);
    };

    return (
        <div className={classes.root} id="filter">
            <Input
                className={classes.inputFilter}
                disabled={!props.data.length}
                id="filterInput"
                placeholder={props.searchPlaceholder}
                value={searchValue}
                onChange={handleInputChange}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        disableRipple
                        className={clsx({ [classes.cursorDefault]: !searchValue.length })}
                        onClick={() => setSearchValue('')}>
                        {
                            searchValue.length ? <CloseIcon /> : <SearchIcon />
                        }
                    </IconButton>
                </InputAdornment>}
            />
            {
                selectValues.length === props.selects?.length ? props.selects?.map((select, index) => <TextField
                    key={index}
                    className={classes.textFieldFilter}
                    disabled={!props.data.length}
                    id="filterTextFieldFunctions"
                    select
                    value={selectValues[index]}
                    label={select.selectLabel}
                    onChange={(e) => handleSelectChange(e.target.value, index)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        displayEmpty: true,
                        MenuProps: {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        },
                    }}
                >
                    <MenuItem value={select.selectAllLabel}>
                        {select.selectAllLabel}
                    </MenuItem>
                    {
                        select.selectOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))
                    }
                </TextField>) : null
            }
            {
                props.download ? <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.open(downloadURL, '_blank')}
                >
                    Baixar
                </Button> : null
            }
        </div>
    );
}

export default Filter;
