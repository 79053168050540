import React from 'react';
import { Route } from 'react-router-dom';

import DefaultLayout from '../components/Layout/Default';
import LoginLayout from '../components/Layout/Login';
import { ROUTES } from '../shared/constants';
import history from '../shared/history';
import { useAppSelector } from '../store/hooks';
import Login from '../pages/login';

type Props = {
    component?: React.ComponentType<any>;
    path: string;
    exact?: boolean;
    notFound?: boolean;
    permissions?: string[];
}

/**
 * Route wrapper
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Router({ component: Component, ...rest }: Props): JSX.Element {
    let Layout = DefaultLayout;
    const { logged, user } = useAppSelector((state) => state.auth);

    if (!logged || rest.path === ROUTES.LOGIN) {
        Layout = LoginLayout;
        Component = Login;
        // window.location.assign(SITE_URL);
    } else if (rest.notFound) {
    } else if ((rest.permissions && user &&
        !rest.permissions.includes(user.role_id.toString()))) {
        history.push(ROUTES.NOT_FOUND);
    }

    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <Layout>
                        {
                            Component ? <Component {...props} /> : null
                        }
                    </Layout>
                );
            }}
        />
    );
};
