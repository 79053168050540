import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItem, Divider, Typography, List, Drawer } from '@material-ui/core';

import HeaderProfile from '../../../assets/img/header-profile.png';
import { useAppSelector } from '../../../store/hooks';
import items from './items';
import AppToolBar from './AppToolBar';
import history from '../../../shared/history';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        authArea: {
            backgroundImage: `url(${HeaderProfile})`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: '#2F4050',
        },
        paperAnchorDockedLeft: {
            borderRight: 'unset',
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        listText: {
            color: '#a7b1c2',
        },
        listItemText: {
            display: 'contents',
            color: '#a7b1c2',
            textDecoration: 'none',
            fontSize: 14,
            height: '50px',
            width: '100%',
        },
        listItemButton: {
            '&:hover': {
                backgroundColor: 'transparent',
                fontWeight: 'bold',
                fontSize: 15,
            },
        },
        listItemIcon: {
            color: '#a7b1c2',
            minWidth: 30,
        },
        listItemActive: {
            fontWeight: 'bold',
            fontSize: 15,
            textDecoration: 'underline',
        },
        authInfo: {
            color: '#a7b1c2',
            textAlign: 'center',
            verticalAlign: 'middle',
            fontSize: 12,
        },
    }),
);

/**
 * PermanentDrawerLeft component
 * @return {JSX.Element}
 */
export default function PermanentDrawerLeft() {
    const classes = useStyles();
    const { user } = useAppSelector((state) => state.auth);

    return (
        <div className={classes.root}>
            <AppToolBar />
            <Drawer
                color="secondary"
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                    paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
                }}
                anchor="left"
            >
                <div className={clsx(classes.toolbar, classes.authArea)}>
                    <Typography className={classes.authInfo}>{user?.email}</Typography>
                    <Typography className={classes.authInfo}>{user?.role.name}</Typography>
                </div>
                <Divider />
                <List disablePadding>
                    {
                        user ?
                            items.map(({ name, path, icon, roles }) => roles.includes(user.role_id.toString()) ?
                                <React.Fragment key={name}>
                                    <ListItem
                                        button
                                        key={path}
                                        classes={{ button: classes.listItemButton }}
                                        onClick={() => history.push(path)}
                                    >
                                        <NavLink
                                            to={path}
                                            activeClassName={classes.listItemActive}
                                            className={classes.listItemText}
                                        >
                                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                                {React.createElement(icon, {})}
                                            </ListItemIcon>
                                            {name}
                                        </NavLink>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment> : null) : null
                    }
                </List>
            </Drawer>
        </div>
    );
}
