/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import intl from 'react-intl-universal';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Button, IconButton } from '@material-ui/core';
import MuiTable from '@material-ui/core/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { MESSAGES, PAGINATION } from '../../shared/constants';
import history from '../../shared/history';
import api from '../../shared/api';
import Dialog from '../Dialog';
import Filter, { SelectProps } from './Filter';
import { toast } from 'react-toastify';
import useStateWithPromise from '../../hooks/useStateWithPromise';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 700,
            height: 50,
            padding: 0,
            paddingLeft: theme.spacing(1),
        },
        checkboxColumn: {
            width: 95,
            textAlign: 'center',
        },
        tableCell: {
            height: 50,
            padding: 0,
            paddingLeft: theme.spacing(1),
        },
        tableContainer: {
            minHeight: 450,
            marginBottom: 22,
        },
        deleteButton: {
            visibility: 'hidden',
        },
        actions: {
            '&:hover $deleteButton': {
                visibility: 'visible',
            },
        },
    }),
);

export interface Field {
    name: string;
    translate?: boolean;
    link?: boolean;
};

interface Props<T> {
    data: T[];
    columns: string[];
    fields: Field[];
    onRefresh: () => void;
    searchPlaceholder: string;
    searchMatchFields: string[];
    selects?: SelectProps[];
    hideActions?: boolean;
    addPath: string;
    editPath: string;
    viewPath?: string;
    deleteEndpoint: string;
    addAction?: boolean;
    editAction?: boolean;
    viewAction?: boolean;
    importAction?: boolean;
    onImport?: () => void;
    getOneAction?: boolean;
    onGetOne?: () => void;
    deleteAction?: boolean;
    download?: boolean;
    downloadUrl?: string;
}

interface DynamicObject {
    [key: string]: any;
}

/**
 * Table component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Table<T extends DynamicObject>(props: Props<T>): JSX.Element {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE[0]);
    const [filtered, setFiltered] = React.useState<T[]>([]);
    const [page, setPage] = useState(0);
    const [paginated, setPaginated] = useState<T[]>([]);
    const [openDialog, setOpenDialog] = useStateWithPromise(false);
    const [toDelete, setToDelete] = useState(0);

    useEffect(() => {
        if (toDelete) {
            setOpenDialog(true);
        }
    }, [toDelete]);

    useEffect(() => {
        if (filtered.length && paginated.length) {
            paginate(0);
        }
    }, [rowsPerPage]);

    useEffect(() => {
        if (filtered.length && !paginated.length) {
            setPage(0);
            paginate(0);
        }
    }, [filtered]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
        paginate(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, PAGINATION.RADIX));
        setPage(0);
    };

    const paginate = (newPage: number) => {
        newPage++;
        const newPaginated = filtered.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
        setPaginated(newPaginated);
    };

    const getValue = (value: T, field: Field) => {
        if (field.link) {
            return value[field.name] ? <Link to={value[field.name]}>Ver</Link> : '-';
        }
        if (field.name.indexOf('.') !== -1) {
            const fields = field.name.split('.');
            const object = value[fields[0]];
            if (object) {
                if (field.translate) {
                    return intl.get(`common.${object[fields[1]]}`);
                }
                return object[fields[1]];
            }
            return '-';
        }
        if (field.translate) {
            return intl.get(`common.${value[field.name]}`);
        }
        return value[field.name] || '-';
    };

    const onDelete = async (id: number) => {
        await api.delete(props.deleteEndpoint.replace(':id', id.toString()));
        setOpenDialog(false);
        toast.success(MESSAGES.DELETE_SUCCESS);
        props.onRefresh();
    };

    const onCloseDialog = async () => {
        setOpenDialog(false);
        setTimeout(() => {
            setToDelete(0);
            // eslint-disable-next-line no-magic-numbers
        }, 100);
    };

    return (
        <div>
            {
                props.addAction ? <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(props.addPath)}
                >
                    Adicionar
                </Button> : null
            }
            {
                props.importAction ? <Button
                    style={{ marginLeft: '24px' }}
                    variant="contained"
                    color="primary"
                    onClick={props.onImport}
                >
                    Importar
                </Button> : null
            }
            {
                props.getOneAction ? <Button
                    style={{ marginLeft: '24px' }}
                    variant="contained"
                    color="primary"
                    onClick={props.onGetOne}
                >
                    Solicitar cliente
                </Button> : null
            }
            <Filter
                data={props.data}
                setFiltered={setFiltered}
                setPaginated={setPaginated}
                setPage={setPage}

                selects={props.selects}

                searchPlaceholder={props.searchPlaceholder}
                searchMatchFields={props.searchMatchFields}

                download={props.download}
                downloadUrl={props.downloadUrl}
            />
            <TableContainer className={classes.tableContainer}>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {
                                props.columns.map((column) => <TableCell key={column} className={classes.tableHeader}>
                                    {column}
                                </TableCell>)
                            }
                            {
                                !props.hideActions ? <TableCell key={'actions'} className={classes.tableHeader}>
                                    Ações
                                </TableCell> : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paginated.map((value, index) => <TableRow key={index}>
                                {
                                    props.fields.map((field) => <TableCell
                                        className={classes.tableCell}
                                        key={field.name}
                                    >
                                        {getValue(value, field)}
                                    </TableCell>)
                                }
                                {
                                    !props.hideActions ? <TableCell
                                        className={clsx(classes.tableCell, classes.actions)}
                                        key={`${index}action`}
                                    >

                                        {
                                            props.viewPath ? <IconButton
                                                aria-label="view"
                                                color="primary"
                                                onClick={() => history.push(props.viewPath ?
                                                    props.viewPath.replace(':id', value.id) : '')}
                                            >
                                                <VisibilityIcon />
                                            </IconButton> : null
                                        }
                                        {
                                            props.editAction ? <IconButton
                                                aria-label="edit"
                                                color="primary"
                                                onClick={() => history.push(props.editPath
                                                    .replace(':id', value.id))}
                                            >
                                                <EditIcon />
                                            </IconButton> : null
                                        }
                                        {
                                            props.deleteAction ? <IconButton
                                                aria-label="delete"
                                                color="primary"
                                                className={classes.deleteButton}
                                                onClick={() => setToDelete(value.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton> : null
                                        }
                                    </TableCell> : null
                                }
                            </TableRow>)
                        }
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <TablePagination
                id="reportsPagination"
                component="div"
                tabIndex={-1}
                count={filtered.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog
                title={'Remover'}
                text={`Tem certeza que deseja remover o registo com id ${toDelete}?`}
                onCancel={() => onCloseDialog()}
                onConfirm={() => onDelete(toDelete)}
                open={openDialog}
            />
        </div>
    );
}

export default Table;
