import axios from 'axios';
import { toast } from 'react-toastify';
import { ENDPOINTS, MESSAGES, API_HOST, ERROR_STATUS, ROUTES } from './constants';
import history from './history';

const instance = axios.create({
    baseURL: `${API_HOST}`,
});

instance.defaults.withCredentials = true;

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.config.url !== ENDPOINTS.USERS.GET.AUTHENTICATED &&
            error.response.config.url !== ENDPOINTS.AUTH.POST.LOGIN &&
            error.response.config.url !== ENDPOINTS.CLIENTS.POST.IMPORT &&
            error.response.config.url !== ENDPOINTS.CLIENTS.PATCH.GET_ONE &&
            error.response.config.url !== ENDPOINTS.CLIENTS.PATCH.RETURN_ONE
        ) {
            if (error.config.method === 'delete') {
                toast.error(MESSAGES.DELETE_ERROR);
            } else if (error.config.method === 'post') {
                toast.error(MESSAGES.SAVE_ERROR);
            } else if (error.config.method === 'get') {
                if (error.response.status === ERROR_STATUS.UNAUTHORIZED) {
                    toast.warning(MESSAGES.SESSION_EXPIRED);
                    history.push(ROUTES.LOGIN);
                } else {
                    toast.error(MESSAGES.GET_ERROR);
                }
            } else {
                toast.error(MESSAGES.GENERIC_ERROR);
            }
        }
        return Promise.reject(error);
    });


export default instance;
