import React, { useState } from 'react';

import { useParams } from 'react-router';
import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import query from 'query-string';

import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppDispatch } from '../../store/hooks';
import { resetPassword } from '../../store/features/auth/authSlice';
import { UTILS } from '../../shared/constants';
import history from '../../shared/history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(3),
        },
        formTitle: {
            marginBottom: 40,
            fontWeight: 500,
            fontSize: 18,
        },
    }),
);

interface FormData {
    password: string;
    passwordConfirmation: string;
}

const validationSchema = yup.object({
    password: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .min(UTILS.PASSWORD_MIN_LEN, UTILS.PASSWORD_MIN_MESSAGE)
        .max(UTILS.STRING_MAX_LEN),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], UTILS.PASSWORD_MUST_MATCH),
});

/**
 * Reset password form component
 * @return {JSX.Element}
 */
function ResetPasswordForm(): JSX.Element {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [disabled, setDisabled] = useState(false);
    const { token } = useParams<{ id: string, token: string }>();
    const params = query.parse(encodeURI(history.location.search));
    let email = '';
    if (typeof params.email === 'string') {
        email = decodeURIComponent(params.email);
    }

    const initialValues = {
        email: email,
        password: '',
        passwordConfirmation: '',
    };

    const onResetPassword = async (values: FormData) => {
        setDisabled(true);
        dispatch(resetPassword({
            email,
            token,
            ...values,
        }));
        setDisabled(false);
    };

    return (<Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            await onResetPassword(values);
            setSubmitting(false);
        }}
    >
        {({ submitForm, isSubmitting }) => (
            <>
                <Grid item xs={12}>
                    <Typography className={classes.formTitle}>Redefina sua senha</Typography>
                </Grid>
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    disabled
                    shrink
                    variant="outlined"
                    name="email"
                    label="Email"
                />
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="password"
                    type="password"
                    label="Nova senha"
                />
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="passwordConfirmation"
                    type="password"
                    label="Confirmar nova senha"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={disabled || isSubmitting}
                    onClick={submitForm}
                >
                    Redefinir senha
                </Button>
            </>
        )}
    </Formik>);
}

export default ResetPasswordForm;
