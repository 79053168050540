/* eslint-disable no-magic-numbers */
import { SelectOption } from '../components/Table/Filter';
import intl from 'react-intl-universal';
import initIntl from './locales';

initIntl();

export const APP_NAME = 'TopCred';

export const RESET_PASSWORD = 'redefinir-a-senha';
export const CREATE_PASSWORD = 'nova-senha';

export const ROUTES = {
    DEFAULT: '/',
    LOGIN: '/login',
    NOT_FOUND: '/404',
    FORGOT_PASSWORD: '/esqueci-a-senha',
    RESET_PASSWORD: `/${RESET_PASSWORD}/:token`,
    CREATE_PASSWORD: `/${CREATE_PASSWORD}/:id/:token`,
    USERS: '/usuarios',
    USERS_ADD: '/usuarios/adicionar',
    USERS_EDIT: '/usuarios/editar/:id',
    CLIENTS: '/clientes',
    CLIENTS_ADD: '/clientes/adicionar',
    CLIENTS_EDIT: '/clientes/editar/:id',
};

export const ENDPOINTS = {
    USERS: {
        GET: {
            AUTHENTICATED: '/users/authenticated',
            ALL: '/users',
            BY_ID: '/users/:id',
        },
        POST: {
            ADD: '/users',
            CREATE_PASSWORD: '/users/create-password/:id/:token',
            FORGOT_PASSWORD: '/users/forgot-password',
            RESET_PASSWORD: '/users/reset-password',
        },
        PATCH: {
            PROFILE: '/users/profile-information',
        },
        PUT: {
            BY_ID: '/users/:id',
        },
        DELETE: {
            BY_ID: '/users/:id',
        },
    },
    CLIENTS: {
        GET: {
            ALL: '/clients',
            BY_ID: '/clients/:id',
            EXPORT: '/clients/export',
        },
        POST: {
            ADD: '/clients',
            IMPORT: '/clients/import',
        },
        PUT: {
            BY_ID: '/clients/:id',
        },
        PATCH: {
            GET_ONE: '/clients/get-one',
            RETURN_ONE: '/clients/return-one',
            TOGGLE_NO_LIMIT_BY_ID: '/clients/toggle-no-limit/:id'
        },
        DELETE: {
            BY_ID: '/clients/:id',
        },
    },
    SECRETARIES: {
        POST: {
            ADD: '/secretaries',
        },
        PUT: {
            BY_ID: '/secretaries/:id',
        },
        DELETE: {
            BY_ID: '/secretaries/:id',
        },
    },
    ROLES: {
        GET: {
            ALL: '/roles',
        },
    },
    IMPORTS: {
        GET: {
            ALL: '/imports',
        },
    },
    AUTH: {
        GET: {
            CSRF: '/csrf-cookie',
        },
        POST: {
            LOGIN: '/login',
            LOGOUT: '/logout',
        },
    },
};

export const UTILS = {
    PASSWORD_MIN_LEN: 8,
    PASSWORD_MIN_MESSAGE: 'A senha deve conter no mínimo 8 caracteres',
    PASSWORD_MUST_MATCH: 'As senhas devem combinar',
    STRING_MAX_LEN: 255,
    TEXT_MAX_LEN: 500,
    REQUIRED_FIELD: 'Campo obrigatório',
    INVALID_FORMAT: 'Formato inválido',
    ALL_LABEL: intl.get('common.all'),
    ALL_VALUE: 'all',
};

export const NUMBERS = {
    THOUSAND: 1000,
};

export const API_HOST = 'https://topcredconsig.com.br/sistema/api/api';
// export const API_HOST = 'http://127.0.0.1:8000/api';

export const PAGINATION = {
    ROWS_PER_PAGE: [10, 20, 50],
    RADIX: 10,
};

export const ROLES = {
    ALL: 'all',
    ADMIN: '1',
    PROMOTER: '2',
};

export const ROLES_OPTIONS: SelectOption[] = [
    { value: ROLES.ADMIN, label: 'Admin' },
    { value: ROLES.PROMOTER, label: 'Promotor(a)' },
];

export const PERMISSIONS: { [key: string]: { [key: string]: string[] } } = {
    USERS: {
        CREATE: [ROLES.ADMIN],
        EDIT: [ROLES.ADMIN],
        REMOVE: [ROLES.ADMIN],
        LIST: [ROLES.ADMIN],
        VIEW: [ROLES.ADMIN],
    },
    CLIENTS: {
        CREATE: [ROLES.ADMIN, ROLES.PROMOTER],
        EDIT: [ROLES.ADMIN, ROLES.PROMOTER],
        REMOVE: [ROLES.ADMIN],
        LIST: [ROLES.ADMIN, ROLES.PROMOTER],
        VIEW: [ROLES.ADMIN, ROLES.PROMOTER],
        IMPORT: [ROLES.ADMIN],
        GET_ONE: [ROLES.PROMOTER],
    },
};

export const MESSAGES = {
    LOGIN_FAILED: 'Usuário ou senha incorretos',
    GENERIC_ERROR: 'Ocorreu um erro de sistema.',
    SESSION_EXPIRED: 'Sua sessão expirou, faça o login novamete.',
    GET_ERROR: 'Ocorreu um erro de sistema ao carregar as informações.',
    SAVE_ERROR: 'Ocorreu um erro de sistema e suas alterações não foram salvas.',
    SAVE_SUCCESS: 'Registro salvo com sucesso.',
    DELETE_ERROR: 'Ocorreu um erro de sistema e seu registro não foi removido.',
    DELETE_SUCCESS: 'Registro removido com sucesso.',
    NO_PERMISSION: 'Você não tem permissão.',
};

export const ERROR_STATUS = {
    UNAUTHORIZED: 401,
};
