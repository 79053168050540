import React from 'react';

import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppDispatch } from '../../store/hooks';
import { login } from '../../store/features/auth/authSlice';
import { APP_NAME, ROUTES, UTILS } from '../../shared/constants';
import history from '../../shared/history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(3),
        },
        forgotPassword: {
            marginTop: 40,
            textAlign: 'center',
            color: '#959595',
            '& a': {
                color: theme.palette.secondary.main,
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        formTitle: {
            marginBottom: 40,
            fontWeight: 500,
            fontSize: 18,
        },
    }),
);

interface FormData {
    email: string;
    password: string;
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email(UTILS.INVALID_FORMAT)
        .required(UTILS.REQUIRED_FIELD),
    password: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .max(UTILS.STRING_MAX_LEN),
});

/**
 * Login form component
 * @return {JSX.Element}
 */
function LoginForm(): JSX.Element {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const initialValues = {
        email: '',
        password: '',
    };

    const onLogin = async (values: FormData) => {
        await dispatch(login({ ...values }));
    };

    return (<Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onLogin(values);
            setSubmitting(false);
        }}
    >
        {({ submitForm, isSubmitting }) => (
            <>
                <Grid item xs={12}>
                    <Typography align='center' className={classes.formTitle}>{APP_NAME}</Typography>
                </Grid>
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="email"
                    type="email"
                    label="Seu email"
                />
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="password"
                    type="password"
                    label="Sua senha"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Entrar
                </Button>
                <Typography className={classes.forgotPassword}>
                    Esqueceu sua senha? <a onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}>Clique aqui</a>
                </Typography>
            </>
        )}
    </Formik>);
}

export default LoginForm;
