import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.secondary.main,
            fontWeight: 700,
            fontSize: 17,
            lineHeight: '19.92px',
            height: 32,
            width: 32,
        },
    }),
);

interface Props {
    name: string;
}

/**
 * Describe your function
 * @param {Props} props
 * @return {any}
 */
export default function LetterAvatar({ name }: Props) {
    const classes = useStyles();

    return (
        <Tooltip title={name} placement="top">
            <Avatar className={classes.avatar}>{name.charAt(0).toUpperCase()}</Avatar>
        </Tooltip>
    );
}
