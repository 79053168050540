import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useParams } from 'react-router';
import query from 'query-string';

import { Box, Grid, Hidden, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import Logo from '../../assets/img/logo.png';
import OrangeLogo from '../../assets/img/orangeLogo.png';

import LoginForm from './LoginForm';
import CreatePasswordForm from './CreatePasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import history from '../../shared/history';
import { RESET_PASSWORD, ROUTES } from '../../shared/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100vh',
        },
        containerMobile: {
            marginTop: 40,
        },
        centerBox: {
            minHeight: 382,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mobilePadding: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        left: {
            backgroundColor: theme.palette.secondary.main,
        },
        logo: {
            objectFit: 'cover',
        },
        logoMobile: {
            objectFit: 'contain',
        },
        form: {
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            padding: 32,
            maxWidth: 418,
            minHeight: 382,
        },
    }),
);

enum Pages {
    NONE = '',
    CREATE_PASSWORD = 'createPassword',
    LOGIN = 'login',
    FORGOT = 'forgot',
    RESET_PASSWORD = 'resetPassword',
};

/**
 * Login component
 * @return {JSX.Element}
 */
function Login(): JSX.Element {
    const classes = useStyles();
    const { id, token } = useParams<{ id: string, token: string }>();

    const [page, setPage] = useState<Pages>(Pages.NONE);
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const params = query.parse(history.location.search);

    useEffect(() => {
        if (id && token) {
            setPage(Pages.CREATE_PASSWORD);
        } else if (history.location.pathname === ROUTES.FORGOT_PASSWORD) {
            setPage(Pages.FORGOT);
        } else if (token && params.email && history.location.pathname.search(RESET_PASSWORD) !== -1) {
            setPage(Pages.RESET_PASSWORD);
        } else {
            setPage(Pages.LOGIN);
        }
    }, [id, token, params, history.location.pathname]);

    const pages: { [key: string]: any } = {
        [Pages.LOGIN]: <LoginForm />,
        [Pages.CREATE_PASSWORD]: <CreatePasswordForm />,
        [Pages.FORGOT]: <ForgotPasswordForm />,
        [Pages.RESET_PASSWORD]: <ResetPasswordForm />,
    };

    return (
        <Grid
            container
            justifyContent="center"
            className={clsx({
                [classes.container]: !mdUp,
            })}
        >
            <Grid item xs={12} className={clsx({
                [classes.mobilePadding]: !mdUp,
            })}>
                <Box className={clsx(classes.centerBox, {
                    [classes.container]: mdUp,
                })}>
                    <Box className={classes.form}>
                        <Grid container justifyContent="center">
                            {pages[page]}
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Login;
