import React, { useState } from 'react';

import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { LinearProgress, Grid, Button } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import * as yup from 'yup';

import api from '../../../shared/api';
import { ENDPOINTS, ROLES, UTILS } from '../../../shared/constants';
import Secretary from '../../../models/Secretary.model';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useAppSelector } from '../../../store/hooks';

const styles = () =>
    createStyles({
        root: {
            margin: 0,
            padding: '58px 61px 28px 59px',
        },
        title: {
            fontWeight: 'bold',
        },
        closeButton: {
            position: 'absolute',
            right: 58,
            top: 55,
        },
    });

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(1),
        },
        mbl: {
            marginBottom: theme.spacing(2),
        },
        mt: {
            marginTop: theme.spacing(2),
        },
        mr: {
            marginRight: theme.spacing(2),
        },
        list: {
            maxHeight: 300,
            overflow: 'auto',
        },
    }),
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogContent = withStyles(() => ({
    root: {
        padding: '0px 61px 0px 59px',
        width: 586,
        maxWidth: 586,
    },
}))(MuiDialogContent);

interface FormData {
    secretary: string;
    registration: string;
}

interface Props {
    open: boolean;
    onCancel: () => void;
    getClient: () => void;
    secretary: Secretary;
};

const validationSchema = yup.object({
    secretary: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .max(UTILS.STRING_MAX_LEN),
    registration: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .max(UTILS.STRING_MAX_LEN),
});

/**
 * Comments component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function EditSecretaryDialog(props: Props): JSX.Element {
    const classes = useStyles();
    const { user } = useAppSelector((state) => state.auth);

    const [initialValues, setInitialValues] = useState({
        secretary: props.secretary.secretary,
        registration: props.secretary.registration,
    });

    const save = async (values: FormData) => {
        await api.put(ENDPOINTS.SECRETARIES.PUT.BY_ID.replace(':id', props.secretary.id.toString()), values);
        props.getClient();
        props.onCancel();
    };

    return (
        <>
            <MuiDialog
                id="dialog"
                open={props.open}
                onClose={props.onCancel}
            >
                <DialogContent>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            await save(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ submitForm, isSubmitting }) => (
                            <Form>
                                <Field
                                    component={TextField}
                                    className={classes.mb}
                                    fullWidth
                                    type="text"
                                    label="Secretaria"
                                    name="secretary"
                                />
                                <Field
                                    component={TextField}
                                    className={classes.mb}
                                    disabled={user && user.role_id.toString() === ROLES.PROMOTER}
                                    fullWidth
                                    type="text"
                                    label="Matrícula"
                                    name="registration"
                                />

                                {isSubmitting && <LinearProgress className={classes.mb} />}
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    className={classes.mbl}
                                >
                                    <Button
                                        variant="contained"
                                        color="default"
                                        className={classes.mt}
                                        onClick={props.onCancel}
                                    >
                                        Cancelar
                                    </Button>
                                    &nbsp;
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.mt}
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </MuiDialog>
        </>
    );
}
