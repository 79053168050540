import React, { useEffect, useState } from 'react';

import Table, { Field } from '../../components/Table';
import {
  API_HOST,
  ENDPOINTS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  UTILS,
} from '../../shared/constants';
import { useAppSelector } from '../../store/hooks';
import Client from '../../models/Client.model';
import api from '../../shared/api';
import { SelectOption, SelectProps } from '../../components/Table/Filter';
import User from '../../models/User.model';
import ImportDialog from './importDialog';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import history from '../../shared/history';

const columns = ['ID', 'Nome', 'CPF', 'Telefone', 'Usuário'];

const fields: Field[] = [
  {
    name: 'id',
  },
  {
    name: 'name',
  },
  {
    name: 'document',
  },
  {
    name: 'phone',
  },
  {
    name: 'user',
  },
];

const noLimitFilter = {
  selectAllLabel: UTILS.ALL_LABEL,
  selectLabel: 'Filtre por limite',
  selectMatchField: 'noLimit',
  selectOptions: [
    {
      label: 'Com limite',
      value: '0',
    },
    {
      label: 'Sem limite',
      value: '1',
    },
  ],
};

/**
 * Clients component
 * @return {JSX.Element}
 */
function Clients(): JSX.Element {
  const [clients, setClients] = useState<Client[]>([]);
  const [selects, setSelects] = useState<SelectProps[]>([]);
  const [usersOptions, setUsersOptions] = useState<SelectOption[]>([]);
  const [importsOptions, setImportsOptions] = useState<SelectOption[]>([]);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getClients();
    getUsers();
    getImports();
  }, []);

  useEffect(() => {
    if (user && user.role_id === parseInt(ROLES.ADMIN)) {
      setSelects([
        {
          selectAllLabel: UTILS.ALL_LABEL,
          selectLabel: 'Filtre por usuário',
          selectMatchField: 'createdBy',
          selectOptions: usersOptions,
        },
        {
          selectAllLabel: UTILS.ALL_LABEL,
          selectLabel: 'Filtre por importação',
          selectMatchField: 'importId',
          selectOptions: importsOptions,
        },
        noLimitFilter,
      ]);
    } else {
      setSelects([noLimitFilter]);
    }
  }, [usersOptions, user, importsOptions]);

  const getClients = async () => {
    const response = await api.get(ENDPOINTS.CLIENTS.GET.ALL);

    let filtered = response.data;
    if (user?.role_id.toString() !== ROLES.ADMIN) {
      let pending: Client | undefined;
      filtered = response.data.filter((item: Client) => {
        if (!item.phone && !item.noLimit) {
          pending = item;
        }
        return (item.phone && !item.noLimit) || (!item.phone && item.noLimit);
      });

      if (pending) {
        filtered.unshift(pending);
      }
    }
    setClients(filtered);
  };

  const handleGetOne = async () => {
    try {
      await api.patch(ENDPOINTS.CLIENTS.PATCH.GET_ONE);
      getClients();
    } catch (error) {
      const axiosError = error as AxiosError;
      const client = axiosError.response?.data;
      if (client) {
        history.push(ROUTES.CLIENTS_EDIT.replace(':id', client.id.toString()));
      }
      toast.error(
        'Finalize o tratamento do cliente pendente para solicitar outro.'
      );
    }
  };

  const getUsers = async () => {
    const response = await api.get(ENDPOINTS.USERS.GET.ALL);
    const options: SelectOption[] = response.data.map(
      (user: User): SelectOption => ({
        label: user.name,
        value: user.id.toString(),
      })
    );
    options.unshift({
      value: '',
      label: 'Sem usuário',
    });
    setUsersOptions(options);
  };

  const getImports = async () => {
    const response = await api.get(ENDPOINTS.IMPORTS.GET.ALL);
    setImportsOptions(response.data);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  return (
    <>
      {user ? (
        <Table
          data={clients}
          columns={columns}
          fields={fields}
          onRefresh={getClients}
          searchMatchFields={['name', 'phone', 'document', 'user']}
          searchPlaceholder={'Pesquise por nome, CPF, usuário ou telefone'}
          addPath={ROUTES.CLIENTS_ADD}
          editPath={ROUTES.CLIENTS_EDIT}
          deleteEndpoint={ENDPOINTS.CLIENTS.DELETE.BY_ID}
          selects={selects}
          onGetOne={handleGetOne}
          getOneAction={PERMISSIONS.CLIENTS.GET_ONE.includes(
            user.role_id.toString()
          )}
          addAction={PERMISSIONS.CLIENTS.CREATE.includes(
            user.role_id.toString()
          )}
          editAction={PERMISSIONS.CLIENTS.EDIT.includes(
            user.role_id.toString()
          )}
          deleteAction={PERMISSIONS.CLIENTS.REMOVE.includes(
            user.role_id.toString()
          )}
          importAction={PERMISSIONS.CLIENTS.IMPORT.includes(
            user.role_id.toString()
          )}
          onImport={() => setOpenImportDialog(true)}
          download
          downloadUrl={`${API_HOST}${ENDPOINTS.CLIENTS.GET.EXPORT}`}
        />
      ) : null}
      <ImportDialog
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        onUpload={() => {
          getClients();
          getImports();
        }}
      />
    </>
  );
}

export default Clients;
