import React from 'react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = () =>
    createStyles({
        root: {
            margin: 0,
            padding: '58px 61px 28px 59px',
        },
        title: {
            fontWeight: 'bold',
        },
        closeButton: {
            position: 'absolute',
            right: 58,
            top: 55,
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5" color="primary" className={classes.title}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({
    root: {
        padding: '0px 61px 0px 59px',
        width: 586,
        maxWidth: 586,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
    root: {
        margin: 0,
        padding: '82px 61px 61px 59px;',
        display: 'flex',
        justifyContent: 'space-around',
    },
}))(MuiDialogActions);

interface CommonProps {
    open: boolean;
    title: string;
    text: string;
    onCancel: () => void;
}

type ConditionalProps =
    // require onConfirm props when onButton is false or undefined
    | { oneButton?: false; onConfirm: () => void }
    // not require onConfirm props when onButton is true
    | { oneButton: true; onConfirm?: never };

type Props = CommonProps & ConditionalProps;

/**
 * Dialog component
 * @param {Props} props
 * @return {any}
 */
function Dialog(props: Props) {
    return (
        <MuiDialog
            id="dialog"
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="dialogTitle"
        >
            <DialogTitle id="dialogTitle" onClose={props.onCancel}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {
                    <Typography id="dialogContent" variant="h5" gutterBottom>
                        {props.text}
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                {
                    props.oneButton ?
                        <Button
                            onClick={props.onCancel}
                            disableTouchRipple
                            id="okButton"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            Ok
                        </Button> :
                        <>
                            <Button
                                onClick={props.onCancel}
                                disableTouchRipple
                                id="cancelButton"
                                variant="outlined"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                Não
                            </Button>
                            <Button
                                onClick={props.onConfirm}
                                disableTouchRipple
                                id="confirmButton"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                            >
                                Sim
                            </Button>
                        </>
                }
            </DialogActions>
        </MuiDialog>
    );
}

export default Dialog;
