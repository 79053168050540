/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Button,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import api from '../../../shared/api';
import { ENDPOINTS, MESSAGES, ROLES } from '../../../shared/constants';
import { useAppSelector } from '../../../store/hooks';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import Dialog from '../../../components/Dialog';
import useStateWithPromise from '../../../hooks/useStateWithPromise';
import EditSecretaryDialog from './formDialog';
import Secretary from '../../../models/Secretary.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb: {
      marginBottom: theme.spacing(1),
    },
    mt: {
      marginTop: theme.spacing(2),
    },
    mr: {
      marginRight: theme.spacing(2),
    },
    list: {
      maxHeight: 300,
      overflow: 'auto',
    },
  })
);

interface SecretariesMap {
  [key: string]: Secretary;
}

interface Props {
  secretaries?: Secretary[];
  clientId: string;
  getClient: () => void;
}

/**
 * Comments component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Secretaries({
  secretaries,
  getClient,
  clientId,
}: Props): JSX.Element {
  const classes = useStyles();
  const [list, setList] = useState<SecretariesMap>({});
  const [secretary, setSecretary] = useState('');
  const [registration, setRegistration] = useState('');
  const [loading, setLoading] = useState(false);
  const [toDelete, setToDelete] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useStateWithPromise(false);
  const [toEdit, setToEdit] = useState<Secretary>();
  const [openEditDialog, setOpenEditDialog] = useStateWithPromise(false);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (toDelete) {
      setOpenDeleteDialog(true);
    }
  }, [toDelete]);

  useEffect(() => {
    if (toEdit) {
      setOpenEditDialog(true);
    }
  }, [toEdit]);

  useEffect(() => {
    if (secretaries && secretaries.length) {
      const secretariesMap: SecretariesMap = {};
      secretaries.forEach((secretary) => {
        secretariesMap[secretary.id] = secretary;
      });
      setList(secretariesMap);
    } else {
      setList({});
    }
  }, [secretaries]);

  const onSave = async () => {
    try {
      setLoading(true);
      const response = await api.post(ENDPOINTS.SECRETARIES.POST.ADD, {
        secretary,
        registration,
        client_id: clientId,
      });
      const added = response.data;
      setList({ [added.id]: added, ...list });
      setSecretary('');
      setRegistration('');
      setLoading(false);
      getClient();
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async (id: number) => {
    await api.delete(
      ENDPOINTS.SECRETARIES.DELETE.BY_ID.replace(':id', id.toString())
    );
    setOpenDeleteDialog(false);
    toast.success(MESSAGES.DELETE_SUCCESS);
    getClient();
  };

  const onCloseDeleteDialog = async () => {
    setOpenDeleteDialog(false);
    setTimeout(() => {
      setToDelete(0);
      // eslint-disable-next-line no-magic-numbers
    }, 100);
  };

  const onCloseEditDialog = async () => {
    setOpenEditDialog(false);
    setTimeout(() => {
      setToEdit(undefined);
      // eslint-disable-next-line no-magic-numbers
    }, 100);
  };

  return (
    <>
      <Typography variant="h6" className={classes.mt}>
        Secretarias
      </Typography>
      <TextField
        label="Secretaria"
        value={secretary}
        fullWidth
        className={clsx(classes.mb, classes.mr)}
        onChange={(e) => setSecretary(e.target.value)}
      />
      <TextField
        label="Matrícula"
        value={registration}
        fullWidth
        className={classes.mb}
        onChange={(e) => setRegistration(e.target.value)}
      />
      {loading && <LinearProgress />}
      <Button
        className={classes.mt}
        variant="contained"
        color="primary"
        onClick={() => onSave()}
        disabled={secretary.length < 5 || registration.length < 5 || loading}
      >
        Salvar
      </Button>
      <Box className={classes.list}>
        <List>
          {Object.values(list)
            .reverse()
            .map((secretary, index) => (
              <ListItem key={index} style={{ cursor: 'default' }}>
                <ListItemText>
                  <b>{secretary.secretary} </b>
                  {secretary.registration}
                </ListItemText>
                <ListItemSecondaryAction>
                  {(user && user.role_id === parseInt(ROLES.ADMIN)) ||
                  secretary.secretary === '-' ? (
                    <>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setToEdit(secretary)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setToDelete(secretary.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Box>
      <Dialog
        title={'Remover'}
        text={`Tem certeza que deseja remover o registo com id ${toDelete}?`}
        onCancel={() => onCloseDeleteDialog()}
        onConfirm={() => onDelete(toDelete)}
        open={openDeleteDialog}
      />
      {toEdit ? (
        <EditSecretaryDialog
          secretary={toEdit}
          onCancel={onCloseEditDialog}
          getClient={getClient}
          open={openEditDialog}
        />
      ) : null}
    </>
  );
}
