import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import User from '../../../models/User.model';
import api from '../../../shared/api';
import history from '../../../shared/history';
import { ENDPOINTS, MESSAGES } from '../../../shared/constants';
import { toast } from 'react-toastify';

export interface AuthState {
    logged: boolean;
    user: User | null;
    loading: boolean;
}

const initialState: AuthState = {
    logged: false,
    loading: true,
    user: null,
};

interface LoginData {
    email: string;
    password: string;
};


interface CreatePasswordData {
    id: string;
    token: string;
    password: string;
    passwordConfirmation: string;
};

interface ResetPasswordData {
    email: string;
    token: string;
    password: string;
    passwordConfirmation: string;
};

export const login = createAsyncThunk<void, LoginData>(
    'auth/login',
    async ({ email, password }, thunkApi) => {
        try {
            await api.get(ENDPOINTS.AUTH.GET.CSRF);
            await api.post(ENDPOINTS.AUTH.POST.LOGIN, { email, password });
            thunkApi.dispatch(logged());
            thunkApi.dispatch(getUser());
            history.push('/');
        } catch (error) {
            toast.error(MESSAGES.LOGIN_FAILED);
        }
    },
);

export const createPassword = createAsyncThunk<void, CreatePasswordData>(
    'auth/createPassword',
    async ({ id, token, password, passwordConfirmation }) => {
        try {
            await api.get(ENDPOINTS.AUTH.GET.CSRF);
            await api.patch(`${ENDPOINTS.USERS.POST.CREATE_PASSWORD.replace(':id', id).replace(':token', token)}`,
                { password, password_confirmation: passwordConfirmation });
            toast.success('Senha definida com sucesso.');
            history.push('/');
        } catch (error) {
            toast.error(MESSAGES.GENERIC_ERROR);
        }
    },
);

export const forgotPassword = createAsyncThunk<void, { email: string }>(
    'auth/forgotPassword',
    async ({ email }) => {
        try {
            await api.get(ENDPOINTS.AUTH.GET.CSRF);
            await api.post(`${ENDPOINTS.USERS.POST.FORGOT_PASSWORD}`,
                { email });
            toast.success('Um link para redefinição de senha foi enviado para o seu email.');
            history.push('/');
        } catch (error) {
            toast.error(MESSAGES.GENERIC_ERROR);
        }
    },
);

export const resetPassword = createAsyncThunk<void, ResetPasswordData>(
    'auth/resetPassword',
    async ({ email, token, password, passwordConfirmation }) => {
        try {
            await api.get(ENDPOINTS.AUTH.GET.CSRF);
            await api.post(`${ENDPOINTS.USERS.POST.RESET_PASSWORD}`,
                {
                    email,
                    token,
                    password,
                    password_confirmation: passwordConfirmation,
                });
            toast.success('Senha redefinida com sucesso.');
            history.push('/');
        } catch (error) {
            toast.error(MESSAGES.GENERIC_ERROR);
        }
    },
);

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
            await api.post(ENDPOINTS.AUTH.POST.LOGOUT);
        } catch (error) {
            console.log('error', error);
        }
    },
);

export const getUser = createAsyncThunk(
    'auth/getUser',
    async () => {
        const response = await api.get(ENDPOINTS.USERS.GET.AUTHENTICATED);
        return response.data;
    },
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logged(state) {
            state.logged = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.logged = true;
                state.user = action.payload;
            })
            .addCase(getUser.rejected, (state) => {
                state.loading = false;
                state.logged = false;
            });
        builder
            .addCase(logout.pending, (state) => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, (state) => {
                state.loading = false;
                state.logged = false;
                state.user = null;
            })
            .addCase(logout.rejected, (state) => {
                state.loading = false;
                state.logged = false;
                state.user = null;
            });
    },
});

export const { logged } = authSlice.actions;

export default authSlice.reducer;
