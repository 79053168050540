import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const defaultTheme = createTheme({
    typography: {
        h1: {
            fontSize: 18,
            fontWeight: 700,
        },
        h2: {
            fontSize: 16,
            fontWeight: 500,
        },
        h3: {
            fontSize: 14,
            fontWeight: 500,
        },
        h6: {
            fontSize: 10,
            fontWeight: 600,
        },
        body1: {
            fontSize: 14,
            fontWeight: 500,
        },
        caption: {
            fontSize: 14,
            fontWeight: 500,
        },
    },
    palette: {
        primary: {
            main: '#164966',
            light: '#3b6d8a',
            dark: '#01263b',
        },
        secondary: {
            main: '#E05E3B',
            light: '#f2957c',
            dark: '#bd2c06',
        },
        // text: {
        //     primary: '#18181C',
        //     secondary: '#bab9b9',
        //     hint: '#707070',
        // },
        // success: {
        //     main: '#408000',
        // },
        // error: {
        //     main: '#B30000',
        // },
        // warning: {
        //     main: '#FF7F00',
        //     light: '#FFEB80',
        // },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1366,
            xl: 1800,
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                color: '#000',
                backgroundColor: '#fff',
            },
        },
        MuiOutlinedInput: {
            input: {
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px white inset',
                    // '-webkit-text-fill-color': 'transparent'
                },
            },
        },
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: 6,
                },
                '*::-webkit-scrollbar-track': {
                    '-webkit-box-shadow': 'none',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(112,112,112,.5)',
                    borderRadius: 10,
                    outline: 'none',
                },
                '@font-face': [

                ],
            },
        },
    },
}, ptBR);

export default defaultTheme;
