import React from 'react';
import Button from '@material-ui/core/Button';
import Home from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../shared/history';
import { ROUTES } from '../../shared/constants';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100%`,
    },
    button: {
        marginTop: 20,
    },
}));

/**
 * NotFound component
 * @return {JSX.Element}
 */
const NotFound = (): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography variant="h4">404</Typography>
            <Typography variant="subtitle1">
                A página não existe ou você não tem permissão para acessá-la!
            </Typography>
            <Button
                color="secondary"
                aria-label="home"
                onClick={() => history.push(ROUTES.DEFAULT)}
                className={classes.button}
            >
                <Home />
            </Button>
        </div>
    );
};

export default NotFound;
