import React, { useEffect, useState } from 'react';

import api from '../../shared/api';

import { ENDPOINTS, PERMISSIONS, ROLES_OPTIONS, ROUTES, UTILS } from '../../shared/constants';
import User from '../../models/User.model';

import Table, { Field } from '../../components/Table';
import { useAppSelector } from '../../store/hooks';

const columns = [
    'ID',
    'Nome',
    'E-mail',
    'Perfil',
];

const fields: Field[] = [
    {
        name: 'id',
    },
    {
        name: 'name',
    },
    {
        name: 'email',
    },
    {
        name: 'role.name',
    },
];

/**
 * Users component
 * @return {JSX.Element}
 */
function Users(): JSX.Element {
    const [users, setUsers] = useState<User[]>([]);
    const { user } = useAppSelector((state) => state.auth);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const response = await api.get(ENDPOINTS.USERS.GET.ALL);
        setUsers(response.data);
    };

    return (
        <>
            {
                user ? <Table
                    data={users}
                    columns={columns}
                    fields={fields}
                    onRefresh={getUsers}

                    searchMatchFields={['name', 'email']}
                    searchPlaceholder={'Pesquise por nome ou email'}

                    selects={[
                        {
                            selectAllLabel: UTILS.ALL_LABEL,
                            selectLabel: 'Filtre por perfil',
                            selectMatchField: 'role_id',
                            selectOptions: ROLES_OPTIONS,
                        },
                    ]}

                    addPath={ROUTES.USERS_ADD}
                    editPath={ROUTES.USERS_EDIT}
                    deleteEndpoint={ENDPOINTS.USERS.DELETE.BY_ID}

                    addAction={PERMISSIONS.USERS.CREATE.includes(user.role_id.toString())}
                    editAction={PERMISSIONS.USERS.EDIT.includes(user.role_id.toString())}
                    deleteAction={PERMISSIONS.USERS.REMOVE.includes(user.role_id.toString())}
                /> : null
            }

        </>
    );
}

export default Users;
