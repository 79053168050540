import React, { CSSProperties, useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import defaultTheme from '../../../shared/theme';
import { ENDPOINTS } from '../../../shared/constants';
import api from '../../../shared/api';

interface Props {
    open: boolean
    onClose: () => void
    onUpload: () => void
}

const textStyle: CSSProperties = {
    cursor: 'default',
};

const dropzoneBoxStyle = (isDragActive: boolean): CSSProperties => ({
    border: `1px solid ${isDragActive ? defaultTheme.palette.primary.main : defaultTheme.palette.text.secondary}`,
    height: 200,
    padding: '14px 19px 13px 19px',
    backgroundColor: '#fcfcfd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
});

const dropzoneContentBoxStyle: CSSProperties = {
    border: `1px solid ${defaultTheme.palette.text.secondary}`,
    borderStyle: 'dashed',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
};


const uploadButtonStyle = (isDragActive: boolean): CSSProperties => ({
    border: `1px solid ${isDragActive ? defaultTheme.palette.primary.main : defaultTheme.palette.text.secondary}`,
});

/**
 * ImportDialog component
 * @param {Props} props
 * @return {JSX.Element}
 */
function ImportDialog(props: Props): JSX.Element {
    const [uploading, setUploading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const IMPORT_ERROR = 'Ocorreu um erro ao importar os dados';
    const IMPORT_SUCCESS = 'Dados importados com sucesso.';

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setUploading(true);
        setValidationErrors([]);
        if (acceptedFiles.length) {
            const postRequestData = new FormData();
            postRequestData.append('file', acceptedFiles[0]);
            try {
                await api.post(ENDPOINTS.CLIENTS.POST.IMPORT, postRequestData);
                props.onUpload();
                toast.success(IMPORT_SUCCESS);
                props.onClose();
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setValidationErrors(error.response?.data.errors.file);
                }
                toast.error(IMPORT_ERROR);
            }
        } else {
            props.onClose();
        }
        setUploading(false);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'text/csv': ['.csv'],
        },
    });

    const handleClose = () => {
        if (uploading) {
            return;
        } else {
            props.onClose();
        }
    };

    return <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">
            <Typography variant='h1'>Importação</Typography>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '16px', paddingTop: 'unset' }}>
            <Box sx={dropzoneBoxStyle(isDragActive)}>
                {
                    uploading ?
                        <Typography style={textStyle} className='dropzoneText' variant='h1'>
                            Importando...
                        </Typography> : null
                }
                {
                    !uploading ? <Box sx={dropzoneContentBoxStyle} {...getRootProps()}>
                        <input id="inputFile" {...getInputProps()} />
                        {
                            <>
                                <Typography style={textStyle} className='dropzoneText' variant='h1'>
                                    Arraste arquivos aqui
                                </Typography>
                                <Typography style={textStyle} className='dropzoneText' variant='h2'>
                                    ou
                                </Typography>
                                <Button
                                    style={uploadButtonStyle(isDragActive)}
                                    color="primary"
                                    className='uploadButton'
                                    variant='contained'
                                >
                                    <Typography className='dropzoneText'>
                                        Selecione um arquivo
                                    </Typography>
                                </Button>
                                <Typography style={textStyle}>
                                    Aceita apenas arquivos .csv
                                </Typography>
                            </>
                        }
                    </Box> : null
                }
                {
                    validationErrors.length ?
                        validationErrors.map((validationError, index) => <Typography
                            key={index}
                            style={{ ...textStyle, marginTop: '8px', marginBottom: '8px' }}
                            color="error"
                            className='dropzoneText'
                            variant='caption'
                        >
                            {validationError}
                        </Typography>) : null
                }
            </Box>
        </DialogContent>
    </Dialog>;
}

export default ImportDialog;
