import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, MenuItem, Toolbar, AppBar, Popover, Button } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { logout } from '../../../../store/features/auth/authSlice';
import ProfileDialog from '../../../../pages/users/profileDialog';
import LetterAvatar from '../../../LetterAvatar';

const drawerWidth = 240;
const useStyles = makeStyles(() =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            backgroundColor: '#f3f3f4',
            marginLeft: drawerWidth,
            borderBottom: '1px solid #E7EAEC',
        },
        accountIconColor: {
            color: '#243646',
        },
    }),
);

/**
 * AppToolBar component
 * @return {JSX.Element}
 */
export default function AppToolBar() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.auth);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        setOpen(true);
        handleMenuClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        {
            user ? <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <div className={classes.grow} />
                    <div className={classes.accountIconColor}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={'primary-search-account-menu'}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <LetterAvatar name={user.name} />
                        </IconButton>
                    </div>
                </Toolbar>
                <Popover
                    id={'userMenu'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={isMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                >
                    {/* <MenuItem onClick={handleClickOpen}>Meus dados</MenuItem> */}
                    <MenuItem onClick={() => dispatch(logout())}>Sair</MenuItem>
                </Popover>
                <ProfileDialog
                    open={open}
                    handleClose={handleClose}
                />
            </AppBar> : null
        }
    </>
    );
}
