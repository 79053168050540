import React, { useState } from 'react';

import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppDispatch } from '../../store/hooks';
import { forgotPassword } from '../../store/features/auth/authSlice';
import { ROUTES, UTILS } from '../../shared/constants';
import history from '../../shared/history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(3),
        },
        formTitle: {
            marginBottom: theme.spacing(5),
            fontWeight: 500,
            fontSize: 18,
        },
        formDescription: {
            color: '#000000',
            marginBottom: theme.spacing(5),
        },
        login: {
            marginTop: 40,
            textAlign: 'center',
            color: '#959595',
            '& a': {
                color: theme.palette.secondary.main,
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
    }),
);

interface FormData {
    email: string;
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email(UTILS.INVALID_FORMAT)
        .required(UTILS.REQUIRED_FIELD),
});

/**
 * Create password form component
 * @return {JSX.Element}
 */
function ForgotPasswordForm(): JSX.Element {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const initialValues = {
        email: '',
    };

    const onForgotPassword = async (values: FormData) => {
        await dispatch(forgotPassword({ ...values }));
    };

    return (<Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onForgotPassword(values);
            setSubmitting(false);
        }}
    >
        {({ submitForm, isSubmitting }) => (
            <>
                <Grid item xs={12}>
                    <Typography className={classes.formTitle}>Esqueceu a senha?</Typography>
                    <Typography variant="h3" className={classes.formDescription}>
                        Não se preocupe. É só nos dizer seu e-mail que
                        enviaremos um link para você cadastrar uma nova senha.
                    </Typography>
                </Grid>
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="email"
                    type="email"
                    label="Seu email"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Enviar
                </Button>
                <Typography className={classes.login}>
                    <a onClick={() => history.push(ROUTES.LOGIN)}>Voltar para o login</a>
                </Typography>
            </>
        )}
    </Formik>);
}

export default ForgotPasswordForm;
