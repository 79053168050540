export const sanitizeString = (input: string) => {
    const specialCharacters = /[//()[\]{}%ªº+=§*¬&$#@!¢^~`¨´;,£?|:''\\\/]/g;
    const spaces = /[^\S\r\n]{2,}/g;

    return input.replace(specialCharacters, '').replace(spaces, ' ').trimStart();
};

export const normalizeString = (text: string) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim().toLowerCase();
};
