import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';
import { PERMISSIONS, ROUTES } from '../shared/constants';

import NotFound from '../pages/notFound';
import Home from '../pages/home';
import Clients from '../pages/clients';
import FormClient from '../pages/clients/form';
import Users from '../pages/users';
import FormUser from '../pages/users/form';

/**
 * Application routes
 * @return {JSX.Element}
 */
export default function Routes(): JSX.Element {
    return (
        <Switch>
            {/* USERS */}
            <Route path={ROUTES.USERS} exact component={Users} permissions={PERMISSIONS.USERS.LIST} />
            <Route path={ROUTES.USERS_ADD} exact component={FormUser} permissions={PERMISSIONS.USERS.CREATE} />
            <Route path={ROUTES.USERS_EDIT} exact component={FormUser} permissions={PERMISSIONS.USERS.EDIT} />
            {/* CLIENTS */}
            <Route path={ROUTES.CLIENTS} exact component={Clients} permissions={PERMISSIONS.CLIENTS.LIST} />
            <Route path={ROUTES.CLIENTS_ADD} exact component={FormClient} permissions={PERMISSIONS.CLIENTS.CREATE} />
            <Route path={ROUTES.CLIENTS_EDIT} exact component={FormClient} permissions={PERMISSIONS.CLIENTS.EDIT} />
            {/* LOGIN */}
            <Route path={ROUTES.LOGIN} exact />
            {/* CREATE_PASSWORD */}
            <Route path={ROUTES.CREATE_PASSWORD} exact />
            {/* FORGOT_PASSWORD */}
            <Route path={ROUTES.FORGOT_PASSWORD} exact />
            {/* RESET_PASSWORD */}
            <Route path={ROUTES.RESET_PASSWORD} exact />
            {/* DEFAULT */}
            <Route path={ROUTES.DEFAULT} exact component={Home} />
            {/* NOT FOUND */}
            <Route path={ROUTES.NOT_FOUND} exact notFound component={NotFound} />
            <Redirect to={ROUTES.NOT_FOUND} />
        </Switch>
    );
};
