import { PERMISSIONS, ROUTES } from '../../../shared/constants';
import { Group, SvgIconComponent } from '@material-ui/icons';

type Item = {
    name: string;
    path: string;
    roles: string[];
    icon: SvgIconComponent;
}

const items: Item[] = [
    {
        name: 'Usuários',
        path: ROUTES.USERS,
        icon: Group,
        roles: PERMISSIONS.USERS.LIST,
    },
    {
        name: 'Clientes',
        path: ROUTES.CLIENTS,
        icon: Group,
        roles: PERMISSIONS.CLIENTS.LIST,
    },
];

export default items;
