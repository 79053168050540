import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
    },
}));

/**
 * Home component
 * @return {JSX.Element}
 */
const Home = (): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography variant="h4">Bem-vind@</Typography>
        </div>
    );
};

export default Home;
