/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Button,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { parse } from 'date-fns';

import { useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { isValid as isValidCPF } from '@fnando/cpf';
import { IMaskInput } from 'react-imask';

import api from '../../shared/api';
import {
  UTILS,
  ENDPOINTS,
  ROUTES,
  MESSAGES,
  ROLES,
} from '../../shared/constants';
import history from '../../shared/history';
import { useAppSelector } from '../../store/hooks';
import Secretaries from './secretaries';
import Secretary from '../../models/Secretary.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb: {
      marginBottom: theme.spacing(1),
    },
    mt: {
      marginTop: theme.spacing(1),
    },
    dialogContent: {
      paddingBottom: theme.spacing(3),
    },
  })
);

interface FormData {
  name: string;
  document: string;
  phone: string;
  birthdate: string;
  observations: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCPF = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCPF(props) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        definitions={{
          '#': /[1-9]/,
        }}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

const TextMaskDate = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCPF(props) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00/00/0000"
        definitions={{
          '#': /[1-9]/,
        }}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

/**
 * FormClient component
 * @return {JSX.Element}
 */
export default function FormClient() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { user } = useAppSelector((state) => state.auth);
  const [secretaries, setSecretaries] = useState<Secretary[]>([]);
  const [noLimit, setNoLimit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    document: '',
    phone: '',
    birthdate: '',
    observations: '',
  });

  useEffect(() => {
    if (id) {
      getClient();
    }
  }, []);

  const validationSchema = yup.object({
    name: yup.string().required(UTILS.REQUIRED_FIELD).max(UTILS.STRING_MAX_LEN),
    document: yup
      .string()
      .required(UTILS.REQUIRED_FIELD)
      .test('document', 'CPF inválido', (cpf) =>
        cpf ? isValidCPF(cpf) : false
      )
      .max(UTILS.STRING_MAX_LEN),
    phone: id
      ? yup.string().nullable().max(UTILS.STRING_MAX_LEN)
      : yup
          .string()
          .nullable()
          .required(UTILS.REQUIRED_FIELD)
          .max(UTILS.STRING_MAX_LEN),
    birthdate: yup
      .date()
      .typeError(UTILS.INVALID_FORMAT)
      .transform((value, originalValue) =>
        parse(originalValue, 'dd/MM/yyyy', new Date())
      )
      .required(UTILS.REQUIRED_FIELD),
    observations: yup.string().nullable(),
  });

  const getClient = async () => {
    const response = await api.get(
      ENDPOINTS.CLIENTS.GET.BY_ID.replace(':id', id)
    );
    response.data.birthdate = response.data.birthdate
      .split('-')
      .reverse()
      .join('/');

    setNoLimit(response.data.noLimit);
    setInitialValues({ ...response.data });
    setSecretaries(response.data.secretaries);
  };

  const save = async (values: FormData) => {
    const data = {
      ...values,
      birthdate: values.birthdate.split('/').reverse().join('-'),
    };

    let response: any;
    if (id) {
      response = await api.put(
        ENDPOINTS.CLIENTS.PUT.BY_ID.replace(':id', id),
        data
      );
    } else {
      response = await api.post(ENDPOINTS.CLIENTS.POST.ADD, data);
    }

    setInitialValues({
      ...values,
    });

    toast.success(MESSAGES.SAVE_SUCCESS);
    history.push(
      `${ROUTES.CLIENTS_EDIT.replace(':id', response.data.id.toString())}`
    );
  };

  const handleReturnOne = async () => {
    try {
      const res = await api.patch(ENDPOINTS.CLIENTS.PATCH.RETURN_ONE);
      if (!res.data.id) {
        toast.error(
          'Cliente não pode ser devolvido, informe matrícula e data de nascimento.'
        );
      } else {
        toast.success('Cliente devolvido com sucesso');
      }
      history.push(ROUTES.CLIENTS);
    } catch (error) {
      toast.error('Ocorreu um erro ao devolver cliente.');
    }
  };

  const handleToggleNoLimit = async () => {
    try {
      const res = await api.patch(
        ENDPOINTS.CLIENTS.PATCH.TOGGLE_NO_LIMIT_BY_ID.replace(':id', id)
      );
      if (!res.data.id) {
        toast.error(
          'Cliente não pode ser atualizado, informe matrícula e data de nascimento .'
        );
      } else {
        toast.success('Cliente atualizado com sucesso');
      }
      history.push(ROUTES.CLIENTS);
    } catch (error) {
      toast.error('Ocorreu um erro ao atualziar cliente.');
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              await save(values);
              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {id ? 'Editar' : 'Adicionar'} Cliente
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.mb}
                      fullWidth
                      type="text"
                      label="Nome"
                      name="name"
                      disabled={
                        !!(user?.role_id === parseInt(ROLES.PROMOTER) && id)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.mb}
                      fullWidth
                      type="text"
                      label="CPF"
                      name="document"
                      disabled={
                        !!(user?.role_id === parseInt(ROLES.PROMOTER) && id)
                      }
                      InputProps={{
                        inputComponent: TextMaskCPF,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.mb}
                      fullWidth
                      type="text"
                      label="Telefone"
                      name="phone"
                      disabled={
                        !!(
                          user?.role_id === parseInt(ROLES.PROMOTER) &&
                          id &&
                          initialValues.phone
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.mb}
                      fullWidth
                      type="text"
                      label="Data de nascimento"
                      name="birthdate"
                      disabled={
                        !!(
                          user?.role_id === parseInt(ROLES.PROMOTER) &&
                          id &&
                          initialValues.birthdate
                        )
                      }
                      InputProps={{
                        inputComponent: TextMaskDate,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.mb}
                      fullWidth
                      multiline
                      maxRows={10}
                      type="text"
                      label="Obervações"
                      name="observations"
                    />
                  </Grid>
                </Grid>
                {isSubmitting && <LinearProgress className={classes.mb} />}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.mt}
                    onClick={() => history.push(ROUTES.CLIENTS)}
                  >
                    Cancelar
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.mt}
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Salvar
                  </Button>
                  &nbsp;
                  {id &&
                    values.name &&
                    values.birthdate &&
                    !!secretaries.length &&
                    !values.phone && (
                      <Tooltip title="Devolva o cliente para a base e ele ficará indisponível durante 6 meses">
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.mt}
                          onClick={handleReturnOne}
                        >
                          CLiente sem contato
                        </Button>
                      </Tooltip>
                    )}
                  &nbsp;
                  {id &&
                    ((values.name &&
                      values.birthdate &&
                      !!secretaries.length &&
                      !values.phone) ||
                      noLimit) && (
                      <Tooltip
                        title={
                          noLimit
                            ? 'Marque o cliente como COM LIMITE'
                            : 'Marque o cliente como SEM LIMITE para poder tratar um novo'
                        }
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.mt}
                          onClick={handleToggleNoLimit}
                        >
                          Cliente {noLimit ? 'com' : 'sem'} limite
                        </Button>
                      </Tooltip>
                    )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={6}>
          {id ? (
            <Secretaries
              clientId={id}
              secretaries={secretaries}
              getClient={getClient}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
