import React, { useEffect, useState } from 'react';
import { createStyles, LinearProgress, makeStyles, Theme, Button, MenuItem, Typography, Grid } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import api from '../../shared/api';
import { UTILS, ENDPOINTS, ROLES, ROLES_OPTIONS, ROUTES, MESSAGES } from '../../shared/constants';
import history from '../../shared/history';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(1),
        },
        mt: {
            marginTop: theme.spacing(1),
        },
        dialogContent: {
            paddingBottom: theme.spacing(3),
        },
    }),
);

interface FormData {
    name: string;
    email: string;
    // eslint-disable-next-line camelcase
    role_id: string;
}

const validationSchema = yup.object({
    name: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .max(UTILS.STRING_MAX_LEN),
    email: yup
        .string()
        .email(UTILS.INVALID_FORMAT)
        .required(UTILS.REQUIRED_FIELD),
    role_id: yup
        .string()
        .min(1, UTILS.REQUIRED_FIELD)
        .oneOf(Object.values(ROLES))
        .required(UTILS.REQUIRED_FIELD),
});

/**
 * FormUser component
 * @return {JSX.Element}
 */
export default function FormUser() {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        role_id: '',
    });
    const rolesOptions = [...ROLES_OPTIONS];
    rolesOptions.shift();

    useEffect(() => {
        if (id) {
            getUser();
        }
    }, []);

    const getUser = async () => {
        const response = await api.get(ENDPOINTS.USERS.GET.BY_ID.replace(':id', id));
        setInitialValues({ ...response.data });
    };

    const save = async (values: FormData) => {
        if (id) {
            await api.put(ENDPOINTS.USERS.PUT.BY_ID.replace(':id', id), values);
        } else {
            await api.post(ENDPOINTS.USERS.POST.ADD, values);
        }
        toast.success(MESSAGES.SAVE_SUCCESS);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                await save(values);
                setSubmitting(false);
                history.push(ROUTES.USERS);
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <Typography variant="h6">{id ? 'Editar' : 'Adicionar'} Usuário</Typography>
                    <Field
                        component={TextField}
                        className={classes.mb}
                        fullWidth
                        type="text"
                        label="Nome"
                        name="name"
                    />
                    <Field
                        component={TextField}
                        className={classes.mb}
                        fullWidth
                        name="email"
                        type="email"
                        label="E-mail"
                    />
                    <Field
                        component={TextField}
                        className={classes.mb}
                        select={true}
                        fullWidth
                        label="Perfil"
                        name="role_id"
                        inputProps={{
                            id: 'role-id',
                        }}
                    >
                        <MenuItem value="">
                            -
                        </MenuItem>
                        {
                            rolesOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))
                        }
                    </Field>
                    {isSubmitting && <LinearProgress className={classes.mb} />}
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.mt}
                            onClick={() => history.goBack()}
                        >
                            Cancelar
                        </Button>
                        &nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.mt}
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
