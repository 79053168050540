import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import history from './shared/history';
import Routes from './routes';
import { useAppSelector, useAppDispatch } from './store/hooks';
import { getUser } from './store/features/auth/authSlice';
import Loading from './components/Loading';
import theme from './shared/theme';
import { ThemeProvider } from '@material-ui/styles';
import GlobalStyle from './styles/global';
import initIntl from './shared/locales';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * App main component
 * @return {JSX.Element}
 */
function App() {
    const dispatch = useAppDispatch();
    const { loading, logged } = useAppSelector((state) => state.auth);

    useEffect(() => {
        initIntl();
        dispatch(getUser());
    }, [dispatch]);

    return loading && !logged ? <Loading /> :
        <ThemeProvider theme={theme}>
            <ToastContainer />
            <Router history={history}>
                <Routes />
                <GlobalStyle />
            </Router>
        </ThemeProvider>;
}

export default App;
