import React from 'react';

import { useParams } from 'react-router';
import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppDispatch } from '../../store/hooks';
import { createPassword } from '../../store/features/auth/authSlice';
import { UTILS } from '../../shared/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(3),
        },
        formTitle: {
            marginBottom: 40,
            fontWeight: 500,
            fontSize: 18,
        },
    }),
);

interface FormData {
    password: string;
    passwordConfirmation: string;
}

const validationSchema = yup.object({
    password: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .min(UTILS.PASSWORD_MIN_LEN, UTILS.PASSWORD_MIN_MESSAGE)
        .max(UTILS.STRING_MAX_LEN),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], UTILS.PASSWORD_MUST_MATCH),
});

/**
 * Create password form component
 * @return {JSX.Element}
 */
function CreatePasswordForm(): JSX.Element {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { id, token } = useParams<{ id: string, token: string }>();
    const initialValues = {
        password: '',
        passwordConfirmation: '',
    };

    const onCreatePassword = async (values: FormData) => {
        await dispatch(createPassword({
            id,
            token,
            ...values,
        }));
    };

    return (<Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onCreatePassword(values);
            setSubmitting(false);
        }}
    >
        {({ submitForm, isSubmitting }) => (
            <>
                <Grid item xs={12}>
                    <Typography className={classes.formTitle}>Cadastrar nova senha</Typography>
                </Grid>
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="password"
                    type="password"
                    label="Nova senha"
                />
                <Field
                    component={TextField}
                    className={classes.mb}
                    fullWidth
                    variant="outlined"
                    name="passwordConfirmation"
                    type="password"
                    label="Confirmar nova senha"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Cadastrar nova senha
                </Button>
            </>
        )}
    </Formik>);
}

export default CreatePasswordForm;
