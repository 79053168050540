import intl from 'react-intl-universal';

const DEFAULT_LANGUAGE = 'pt-BR';

type Locales = {
    [key: string]: string
}

/**
 * Initialize internationalization
 * @return {void}
 */
export default function initIntl(): void {
    const locales: Locales = {
        'pt-BR': require('../locales/pt-BR.json'),
    };

    const currentLocale = locales[navigator.language] ? navigator.language : DEFAULT_LANGUAGE;

    intl.init({ currentLocale, locales });
};
