import React from 'react';

import { Container, CssBaseline, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PermanentDrawerLeft from './PermanentDrawerLeft';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        container: {
            backgroundColor: '#f3f3f4',
            position: 'absolute',
            maxWidth: 'unset',
            top: 0,
            bottom: 0,
        },
        box: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginLeft: theme.spacing(3*10),
            padding: theme.spacing(3),
            minHeight: '89vh',
        },
    }),
);

/**
 * Default layout
 * @param {React.PropsWithChildren<Props>} props
 * @return {JSX.Element}
 */
function DefaultLayout(props: React.PropsWithChildren<{}>): JSX.Element {
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <PermanentDrawerLeft />
            <Container maxWidth={false} id="defaultLayout" className={classes.container}>
                <div className={classes.toolbar} />
                <Paper elevation={1} square={false} className={classes.box}>{props.children}</Paper>
            </Container>
        </>
    );
};

export default DefaultLayout;
