import React from 'react';

import { Container, CssBaseline } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: '#f3f3f4',
            position: 'absolute',
            maxWidth: 'unset',
            top: 0,
            bottom: 0,
        },
    }),
);

/**
 * Login layout
 * @param {React.PropsWithChildren<Props>} props
 * @return {JSX.Element}
 */
function LoginLayout(props: React.PropsWithChildren<{}>): JSX.Element {
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <Container id="loginLayout" className={classes.container} disableGutters>
                <>{props.children}</>
            </Container>
        </>
    );
};

export default LoginLayout;
