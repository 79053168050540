import React, { useEffect, useState } from 'react';
import { createStyles, LinearProgress, makeStyles, Theme, DialogTitle, DialogContent, Dialog, Button } from '@material-ui/core';

import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import api from '../../../shared/api';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getUser } from '../../../store/features/auth/authSlice';
import { UTILS, ENDPOINTS } from '../../../shared/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mb: {
            marginBottom: theme.spacing(3),
        },
        dialogContent: {
            paddingBottom: theme.spacing(3),
        },
    }),
);

interface Props {
    open: boolean;
    handleClose: () => void;
}

const validationSchema = yup.object({
    name: yup
        .string()
        .required(UTILS.REQUIRED_FIELD)
        .max(UTILS.STRING_MAX_LEN),
    email: yup
        .string()
        .email(UTILS.INVALID_FORMAT)
        .required(UTILS.REQUIRED_FIELD),
});

/**
 * ProfileDialog component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ProfileDialog(props: Props) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (props.open) {
            dispatch(getUser());
        }
    }, [props.open]);

    useEffect(() => {
        if (user) {
            setInitialValues({
                name: user.name,
                email: user.email,
            });
        }
    }, [user]);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title">Meus dados</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await api.patch(ENDPOINTS.USERS.PATCH.PROFILE, values);
                        setSubmitting(false);
                        props.handleClose();
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Field
                                component={TextField}
                                className={classes.mb}
                                fullWidth
                                type="text"
                                label="Nome"
                                name="name"
                            />
                            <Field
                                component={TextField}
                                className={classes.mb}
                                fullWidth
                                name="email"
                                type="email"
                                label="E-mail"
                            />
                            {isSubmitting && <LinearProgress className={classes.mb} />}
                            <Button
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                Salvar
                            </Button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
